/**
 * ================================================
 * factory -> Labels -> factory Labels -> API
 * This factory contains all the methods for interacting with the factory labels API.
 * ================================================
 */

/**
 * ================================================
 * Base packages
 * ================================================
 */
import type { UseMutationOptions } from '@tanstack/vue-query'
import { useMutation } from '@tanstack/vue-query'

/**
 * ================================================
 * Custom packages
 * ================================================
 */

import type * as T from './types'

/**
 * ================================================
 * API Routes
 * ================================================
 */

/**
 * getFactoryLabels - Call
 */
const getFactoryLabels = async (
  payload: T.getFactoryLabelsRequest
): Promise<T.getFactoryLabelsResponse> =>
  await useWretch('FrAPI')
    .url(`/factory/FactoryLabel/${payload.job}/LSR/Base64`)
    .get()
    .text()
    .then((res) => {
      const blob = window.URL.createObjectURL(
        new Blob([blobHelper(res, payload.job, 'application/pdf')], {
          type: 'application/pdf'
        })
      )

      return blob
    })
    .catch((err) => err)

/**
 * getFactoryLabels - Mutation
 */
const useGetFactoryLabel = (
  options?: UseMutationOptions<
    T.getFactoryLabelsResponse,
    unknown,
    T.getFactoryLabelsRequest,
    Error
  >
) =>
  useMutation({
    mutationFn: getFactoryLabels,
    ...options
  })

/**
 * ================================================
 * Queries
 * ================================================
 */
export const queries = {}

/**
 * ================================================
 * Mutations
 * ================================================
 */
export const mutations = {
  useGetFactoryLabel
}

/**
 * ================================================
 * Factory
 * ================================================
 */
export const factoryLabelsFactoryLabelsAPIFactory = {
  queries,
  mutations
}
