import type * as PRT from './performance-review.types'
import type * as GT from './goals.types'

export async function getEmployeeGoals(
  companyId: string,
  employeeId: string,
  openGoalsOnly = true,
  currentReviewGoalsOnly = false
): Promise<GT.Goal[]> {
  return await useWretch('FrAPI')
    .get(
      `/performance_review/Goals/${companyId}/${employeeId}?openGoalsOnly=${openGoalsOnly}&currentReviewGoalsOnly=${currentReviewGoalsOnly}`
    )
    .json<GT.Goal[]>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in getEmployeeGoals:', err)
      return []
    })
}

async function deleteEmployeeGoal(goalId: number) {
  return await useWretch('FrAPI')
    .delete(`/performance_review/Goals/${goalId}`)
    .json<PRT.GenericResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in deleteEmployeeGoal:', err)
      return null
    })
}

async function createEmployeeGoals(
  companyId: string,
  employeeId: string,
  createEmployeeGoalsRequest: GT.CreateEmployeeGoalRequest[]
) {
  return await useWretch('FrAPI')
    .post(
      JSON.stringify(createEmployeeGoalsRequest),
      `/performance_review/Goals/${companyId}/${employeeId}`
    )
    .json<PRT.GenericResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in createEmployeeGoals:', err)
      return null
    })
}

async function updateEmployeeGoal(
  goalId: number,
  updateEmployeeGoalRequest: GT.UpdateEmployeeGoalRequest
) {
  return await useWretch('FrAPI')
    .put(
      JSON.stringify(updateEmployeeGoalRequest),
      `/performance_review/Goals/${goalId}`
    )
    .json<PRT.GenericResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in updateEmployeeGoal:', err)
      return null
    })
}

async function approveEmployeeGoal(
  goalId: number,
  approveEmployeeGoalRequest: GT.ApproveEmployeeGoalRequest
) {
  return await useWretch('FrAPI')
    .put(
      JSON.stringify(approveEmployeeGoalRequest),
      `/performance_review/Goals/${goalId}`
    )
    .json<PRT.GenericResponse>()
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error('Error in approveEmployeeGoal:', err)
      return null
    })
}

export const goals = {
  getEmployeeGoals,
  createEmployeeGoals,
  deleteEmployeeGoal,
  updateEmployeeGoal,
  approveEmployeeGoal
}
